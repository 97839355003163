import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './Register.module.css';
import bootstrapStyles from './RegisterBootstrap.module.css';

const Register = ({ onRegister }) => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            alert("Passwords don't match");
            return;
        }
        onRegister(username, email, password);
    };

    return (
        <div className={`${bootstrapStyles.container} ${styles.registerContainer}`}>
            <div className={`${bootstrapStyles.card} ${styles.registerCard}`}>
                <div className={bootstrapStyles['card-body']}>
                    <h2 className={`${bootstrapStyles['card-title']} ${bootstrapStyles['text-center']} ${styles.registerTitle}`}>Register</h2>
                    <form onSubmit={handleSubmit}>
                        <div className={bootstrapStyles['mb-3']}>
                            <label htmlFor="username" className={bootstrapStyles['form-label']}>Username</label>
                            <input
                                type="text"
                                className={bootstrapStyles['form-control']}
                                id="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                            />
                        </div>
                        <div className={bootstrapStyles['mb-3']}>
                            <label htmlFor="email" className={bootstrapStyles['form-label']}>Email address</label>
                            <input
                                type="email"
                                className={bootstrapStyles['form-control']}
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className={bootstrapStyles['mb-3']}>
                            <label htmlFor="password" className={bootstrapStyles['form-label']}>Password</label>
                            <input
                                type="password"
                                className={bootstrapStyles['form-control']}
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>
                        <div className={bootstrapStyles['mb-3']}>
                            <label htmlFor="confirmPassword" className={bootstrapStyles['form-label']}>Confirm Password</label>
                            <input
                                type="password"
                                className={bootstrapStyles['form-control']}
                                id="confirmPassword"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            />
                        </div>
                        <div className={bootstrapStyles['d-grid']}>
                            <button type="submit" className={`${bootstrapStyles.btn} ${bootstrapStyles['btn-primary']} ${styles.registerButton}`}>Register</button>
                        </div>
                    </form>
                    <div className={`${bootstrapStyles['text-center']} ${bootstrapStyles['mt-3']} ${styles.loginLink}`}>
                        Already have an account? <Link to="/login">Login here</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;
