import React, {useState, useCallback, useEffect} from "react";
import styles from "./ProfileIconSquare.module.css";


const ProfileIconSquare = ({children, label, customClass, customLabelClass, customBackground, onClick }) => {
    return (
        <div className={customClass ? customClass:styles.profileIconSquare}
             onClick={onClick}
             style={{ cursor: onClick ? 'pointer' : 'default' }} >
            <div className={styles.iconSquare} style={{backgroundColor: customBackground}}>
                {children}
            </div>
            <div className={customLabelClass ?? styles.label} style={{color: customBackground}}>
                {label}
            </div>
        </div>
    )
}

export default ProfileIconSquare;
