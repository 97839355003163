// userSettingsMenu.js
import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import styles from './UserSettingsMenu.module.css';
import { IoTicketOutline } from "react-icons/io5";
import { IoBagAddOutline } from "react-icons/io5";
import { IoBatteryChargingOutline } from "react-icons/io5";
import { IoBatteryDeadOutline } from "react-icons/io5";
import { IoStorefrontSharp } from "react-icons/io5";
import { SiHomeassistantcommunitystore } from "react-icons/si";
import { CgListTree } from "react-icons/cg";
import { TbSettings } from "react-icons/tb";
import { MdManageAccounts } from "react-icons/md";
import { SiChartdotjs } from "react-icons/si";
import { SiContactlesspayment } from "react-icons/si";
import { PiContactlessPayment } from "react-icons/pi";
import { CiLogout } from "react-icons/ci";





const UserSettingsMenu = ({ handleShowHeader, handleShowSettingsHeader, handleLogout }) => {

    useEffect(() => {
        handleShowHeader(false);
        handleShowSettingsHeader(true);
    }, [handleShowHeader, handleShowSettingsHeader]);

    return (
        <nav className={styles.userSettingsMenu}>
            <h2 className={styles.title}>Settings</h2>
            <ul className={styles.menuList}>
                <div className={styles.section}>
                    <h2 className={styles.sectionHeader}>Manage your account</h2>
                    <li className={styles.menuItem}>
                        <Link to="/location-settings/inactive-deals" className={styles.menuLink}>
                            <div className={styles.iconAndName}>
                                <IoBatteryDeadOutline />
                                Inactive Deals
                            </div>
                                <span className={styles.arrow}>›</span>
                        </Link>
                    </li>
                </div>

                <div className={styles.section}>
                    <h2 className={styles.sectionHeader}>Set your location settings</h2>

                    <li className={styles.menuItem}>
                        <Link to="/location-settings/location-settings" className={styles.menuLink}>
                            <div className={styles.iconAndName}>
                                <TbSettings />
                                Location Settings
                            </div>
                                <span className={styles.arrow}>›</span>
                        </Link>
                    </li>
                </div>
                <div className={styles.section}>
                    <h2 className={styles.sectionHeader}>Logout</h2>

                    <li className={styles.menuItem}>
                        <Link className={styles.menuLink}>
                            <div className={styles.iconAndName} onClick={handleLogout}>
                                <CiLogout />
                                Logout
                                <span className={styles.arrow}>›</span>
                            </div>
                        </Link>
                    </li>
                </div>
            </ul>
        </nav>
    )
};

export default UserSettingsMenu;

