// InactiveDeals.js
import React from 'react';

const InactiveDeals = () => {
    return (
        <div>
            <h2>Inactive Deals</h2>
            {/* Add content for managing inactive deals */}
        </div>
    );
};

export default InactiveDeals;

