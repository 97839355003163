// src/utils/authApi.js
import axios from 'axios';
import constants from './constants';

const authApi = {
    login: async (email, password) => {
        const response = await axios.post(`${constants.AUTH_API_URL}/login-user`, { email, password });
        if (response.data.token) {
            localStorage.setItem('user', JSON.stringify(response.data));
        }
        return response.data;
    },

    logout: () => {
        localStorage.removeItem('user');
    },

    register: async (username, email, password) => {
        return await axios.post(`${constants.AUTH_API_URL}/register-user`, { username, email, password });
    },

    getCurrentUser: () => {
        return JSON.parse(localStorage.getItem('user'));
    },

    authHeader: () => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.token) {
            return { Authorization: 'Bearer ' + user.token };
        } else {
            return {};
        }
    }
};

export default authApi;
