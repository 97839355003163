import React, { useEffect } from 'react';

const ManageDispensaries = ({ handleShowHeader, handleShowSettingsHeader }) => {
    useEffect(() => {
        handleShowHeader(false);
        handleShowSettingsHeader(true);
    }, [handleShowHeader, handleShowSettingsHeader]);

    return (
        <div></div>
    );
};

export default ManageDispensaries;
