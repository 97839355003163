import React, {useState} from "react";
import styles from "./Business.module.css";
import BusinessProfile from "../BusinessProfile/BusinessProfile";
import {Route, Link} from "react-router-dom";
import {MdOutlineRemoveRedEye} from "react-icons/md";
import DealPills from "../DealPills/DealPills";
import {PiMapPinAreaBold} from "react-icons/pi";
import pluugApi from "../../utils/pluug.api";
import StarRating from "../StarRating/StarRating";

const Business = ({ business }) => {
    return (
        <Link to={`/business/${business.id}`} className={styles.BusinessLink}>
            <div className={styles.Business}>
                <div className={styles.distance}>
                    <PiMapPinAreaBold/>
                    <span className={styles.distanceText}>
                {business.distance_mi ? business.distance_mi:business.distance} mi.
            </span>
                </div>
                <div className={styles.imageContainer}>
                    <img src={business?.avatar_image?.small_url} alt=""/>
                </div>
                <div className={styles.infoColumn}>
                    <h2 className={styles.businessLink}>

                        {business.name}
                        <div className={styles.engagement}>
                            <MdOutlineRemoveRedEye/>
                            {business.engagement}
                        </div>
                    </h2>
                    <div className={styles.cityState}>{business.city}, {business.state}</div>
                    <div className={styles.ratingWrap}>
                        <StarRating rating={business.rating} maxRating={5} size={15}/>
                        <h3 className={styles.rating}>{`${business.rating}/5`}</h3>

                    </div>
                    <div className={styles.BusinessInformation}>
                        {business.deals && (<DealPills deals={business?.deals?.splice(0,6)}/>)}

                    </div>
                </div>

            </div>
        </Link>

    );
};

export default Business;
