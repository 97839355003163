import React from "react";
import styles from "./BusinessList.module.css";
import BusinessAdmin from "../Business/BusinessAdmin";

const BusinessListAdmin = ({ businesses }) => {
  return (
    <div className={styles.BusinessList}>
      {businesses.map((business) => {
        return <BusinessAdmin business={business} key={business.id} />;
      })}
    </div>
  );
};

export default BusinessListAdmin;
