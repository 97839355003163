import React, { useState, useCallback, useRef, useEffect, forwardRef, useImperativeHandle, useMemo } from "react";
import styles from "./SearchBar.module.css";
import useApi from "../../utils/useApi";
import { BiMenuAltRight } from "react-icons/bi";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import { FiThumbsUp } from "react-icons/fi";
import geolocation from "../../utils/geolocation";
import { PiMapPinAreaBold } from "react-icons/pi";

const SearchBar = forwardRef(({ onSearchResults, geoposition, children, onSearchTermChange }, ref) => {
    const { isLoading, error, search: apiSearch, searchGeo } = useApi();
    const [sortBy, setSortBy] = useState("near_you");
    const [location, setLocation] = useState("");
    const [term, setTerm] = useState("");
    const [useCurrentLocation, setUseCurrentLocation] = useState(false);
    const searchTimeoutRef = useRef(null);
    const initialRenderRef = useRef(true);
    const prevGeopositionRef = useRef();

    const sortByOptions = useMemo(() => ({
        "Near You": {
            key: "near_you",
            icon: BiMenuAltRight
        },
        "Favorites": {
            key: "favorites",
            icon: FaRegHeart,
            activeIcon: FaHeart
        },
        "For You": {
            key: "for_you",
            icon: FiThumbsUp
        },
    }), []);

    const performSearch = useCallback(async (useCurrentLocationOverride = false) => {
        console.warn("Performing search");
        console.warn("Current location:", location);
        console.warn("Current geoposition:", geoposition);
        try {
            let results;
            if (location && !useCurrentLocationOverride && !useCurrentLocation) {
                results = await apiSearch(term, location, sortBy);
            } else {
                const geo = useCurrentLocationOverride || useCurrentLocation ? geoposition : geolocation.getStoredLocation();
                results = await searchGeo(term, geo, sortBy);
            }

            onSearchResults(results);

            localStorage.setItem('lastSearchedTerm', term);
            if (!useCurrentLocation && !useCurrentLocationOverride) {
                localStorage.setItem('lastSearchedLocation', location);
            }
        }
        catch (error) {
            console.error("Error searching:", error);
        }
    }, [term, location, sortBy, onSearchResults, useCurrentLocation, geoposition, apiSearch, searchGeo]);

    useImperativeHandle(ref, () => ({
        performSearch: () => performSearch()
    }));

    useEffect(() => {
        if (initialRenderRef.current) {
            const lastSearchedTerm = localStorage.getItem('lastSearchedTerm');
            const lastSearchedLocation = localStorage.getItem('lastSearchedLocation');

            if (lastSearchedTerm) {
                setTerm(lastSearchedTerm);
                onSearchTermChange(lastSearchedTerm);
            }
            if (lastSearchedLocation) {
                setLocation(lastSearchedLocation);
            }

            if (lastSearchedTerm || lastSearchedLocation) {
                performSearch();
            } else {
                performSearch(true);
            }

            initialRenderRef.current = false;
        }
    }, []);

    useEffect(() => {
        if (!initialRenderRef.current &&
            (prevGeopositionRef.current?.city !== geoposition?.city ||
             prevGeopositionRef.current?.state_abbr !== geoposition?.state_abbr)) {
            console.log("Geoposition changed, performing new search");
            setLocation(`${geoposition.city}, ${geoposition.state_abbr}`);
            performSearch(true);
        }
        prevGeopositionRef.current = geoposition;
    }, [geoposition, performSearch]);

    const debouncedSearch = useCallback(() => {
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
            performSearch();
        }, 1000);  // Increased debounce time to 1000ms
    }, [performSearch]);

    const handleLocationChange = useCallback((ev) => {
        setLocation(ev.target.value);
        setUseCurrentLocation(false);
        debouncedSearch();
    }, [debouncedSearch]);

    const handleTermChange = useCallback((ev) => {
        setTerm(ev.target.value);
        onSearchTermChange(ev.target.value);
        debouncedSearch();
    }, [debouncedSearch, onSearchTermChange]);

    const handleSortByChange = useCallback((newSortBy) => {
        setSortBy(sortByOptions[newSortBy].key);
        performSearch();
    }, [sortByOptions, performSearch]);

    const handleUseCurrentLocation = useCallback(() => {
        setUseCurrentLocation(true);
        setLocation(`${geoposition.city}, ${geoposition.state_abbr}`);
        performSearch(true);
    }, [geoposition, performSearch]);

    const renderSortByOptions = useMemo(() => {
        return Object.keys(sortByOptions).map((sortByOption) => {
            const option = sortByOptions[sortByOption];
            const isActive = sortBy === option.key;
            const Icon = isActive && option.activeIcon ? option.activeIcon : option.icon;

            return (
                <li
                    className={isActive ? styles.active : ''}
                    onClick={() => handleSortByChange(sortByOption)}
                    key={option.key}
                >
                    <Icon/>
                    {sortByOption}
                </li>
            );
        });
    }, [sortByOptions, sortBy, handleSortByChange]);

    return (
        <div className={styles.SearchBar}>
            <div className={styles.SearchBarSortOptions}>
                <ul>{renderSortByOptions}</ul>
            </div>
            <div className={styles.MapContainer}>
                {children}
                <button
                    className={styles.UseCurrentLocationButton}
                    onClick={handleUseCurrentLocation}
                >
                    <PiMapPinAreaBold size={24}/>
                </button>
            </div>

            {/* <div className={styles.SearchBarFields}>
                <input onChange={handleTermChange} placeholder="Search Businesses" value={term}/>
                <input
                    onChange={handleLocationChange}
                    placeholder={useCurrentLocation ? "Using current location" : "Where?"}
                    value={location}
                />
                <div className={styles.SearchBarSubmit} onClick={() => performSearch()}>
                    <a>Pluug</a>
                </div>
            </div> */}
            <div className={styles.loading}>
                {isLoading && <p>Loading...</p>}
            </div>
            {error && <p>Error: {error}</p>}
        </div>
    );
});

export default React.memo(SearchBar);
