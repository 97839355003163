import React, {useState, useEffect, useCallback, useMemo} from 'react';
import {Route, Routes, Navigate, useNavigate, useLocation} from 'react-router-dom';
import {FaMapLocationDot} from "react-icons/fa6";
import {SiMaplibre} from "react-icons/si";
import {BsPersonCircle} from "react-icons/bs";
import {PiMapPinAreaBold} from "react-icons/pi";
import {IoArrowBackCircleOutline} from "react-icons/io5";

import styles from './AppRouter.module.css';
import logo from '../../assets/pluug-logo.svg';

import BusinessProfile from "../BusinessProfile/BusinessProfile";
import Results from "../Results/Results";
import IconSquare from "../IconSquare/IconSquare";
import MainNav from "../MainNav/MainNav";
import DealPostForm from "../DealPostForm/DealPostForm";
import ProtectedRoute from "../ProtectedRoute/ProtectedRoute";
import authApi from '../../utils/auth.api';
import Login from "../Auth/Login";
import Register from "../Auth/Register";
import LocationSettingsMenu from "../LocationSettingsMenu/LocationSettingsMenu";

// New components (you'll need to create these)
import DealManager from "../AdminSettings/DealManager/DealManager";
import LocationManager from "../AdminSettings/LocationManager/LocationManager";
import ActiveDeals from "../AdminSettings/ActiveDeals/ActiveDeals";
import InactiveDeals from "../AdminSettings/InactiveDeals/InactiveDeals";
import Locations from "../AdminSettings/Locations/Locations";
import LocationSettings from "../AdminSettings/LocationSettings/LocationSettings";
import UserAdminManager from "../AdminSettings/UserAdminManager/UserAdminManager";
import LocationDashboard from "../AdminSettings/LocationDashboard/LocationDashboard";
import SubscriptionSettings from "../AdminSettings/SubscriptionSettings/SubscriptionSettings";

// Super Admin Components
import SuperAdminDashboard from "../SuperAdminSettings/SuperAdminDashboard/SuperAdminDashboard";
import ManageDispensaries from "../SuperAdminSettings/ManageDispensaries/ManageDispensaries";
import ManageUsers from "../SuperAdminSettings/ManageUsers/ManageUsers";
import AssignUserToDispensary from "../SuperAdminSettings/AssignUserToDispensary/AssignUserToDispensary";
import SuperAdminSettingsMenu from "../SuperAdminSettings/SuperAdminSettingsMenu/SuperAdminSettingsMenu";
import UserSettingsMenu from "../UserSettingsMenu/UserSettingsMenu";
import LocationAdminMenu from "../LocationAdminMenu/LocationAdminMenu";
import SearchApi from "../Search/Search";

const AppRoutes = (props) => {
    const [showHeader, setShowHeader] = useState(true);
    const [showSettingsHeader, setShowSettingsHeader] = useState(false);
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);
    const [showBottomSheet, setShowBottomSheet] = useState(false);
    const [locationInput, setLocationInput] = useState('');
    const logoSrc = logo;
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const currentUser = authApi.getCurrentUser();
        console.log(currentUser);
        if (currentUser) {
            setUser(currentUser);
            navigate('/');
        }
    }, []);

    const handleShowHeader = (newState) => {
        setShowHeader(newState);
    }

    const handleShowSettingsHeader = (newState) => {
        setShowSettingsHeader(newState);
    }

    const handleLogin = async (email, password) => {
        try {
            const userData = await authApi.login(email, password);
            setUser(userData);
            navigate('/');
        }
        catch (error) {
            console.error('Login failed:', error);
            setError('Login failed. Please check your credentials.');
        }
    };

    const handleRegister = async (username, email, password) => {
        try {
            const response = await authApi.register(username, email, password);
            console.log(response);
            if (response?.data?.success) {
                await handleLogin(email, password);
            } else {
                console.error('Registration failed:', response.message);
                setError(response.message);
            }
        }
        catch (error) {
            console.error('Registration error:', error);
            setError('An unexpected error occurred. Please try again.');
        }
    };

    const handleLogout = () => {
        authApi.logout();
        setUser(null);
        navigate('/login');
    };

    const handleLocationInputChange = useCallback((e) => {
        setLocationInput(e.target.value);
    }, []);

    const handleLocationChange = useCallback((e) => {
        e.preventDefault();
        console.log("Submitting location change:", locationInput);
        props.changeLocation(locationInput);
        setLocationInput('');
        setShowBottomSheet(false);
    }, [locationInput, props.changeLocation]);

    const toggleBottomSheet = useCallback(() => {
        setShowBottomSheet(prev => !prev);
    }, []);

    useEffect(() => {
        console.log("Current geoposition:", props.geoposition);
    }, [props.geoposition]);

    const renderHeader = useCallback(() => {
        return (
            <div>
                <div className={styles.header}>
                    <div className={user ? styles.upperHeader : styles.upperHeaderCentered}>
                        <div className={styles.logoWrap}>
                            <img src={logoSrc} alt="Logo" className={styles.logo}/>
                        </div>
                        {user && props.geoposition && props.geoposition.city && (
                            <div
                                className={styles.locationHeader}
                                onClick={() => setShowBottomSheet(true)}
                            >
                                <span className={styles.city}>
                                    {props.geoposition.city}, {props.geoposition.state_abbr}
                                </span>
                                <IconSquare>
                                    <PiMapPinAreaBold/>
                                </IconSquare>
                            </div>
                        )}
                    </div>
                    {user && (
                        <div className={styles.lowerHeader}>
                            {props?.geoposition?.currentDate}
                        </div>
                    )}
                </div>
            </div>
        );
    }, [user, props.geoposition, logoSrc]);

    const renderSettingsHeader = () => {
        const getPageName = () => {
            const path = location.pathname;
            const pathParts = path.split('/');
            const lastPart = pathParts[pathParts.length - 1];
            return lastPart.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        };

        return (
            <div>
                <div className={styles.header}>
                    <div className={styles.upperSettingsHeader}>
                        <div className={styles.backLink}>
                            <button onClick={() => navigate(-1)} className={styles.backButton}>
                                <IoArrowBackCircleOutline/>
                            </button>
                            <div className={styles.pageName}>
                                {getPageName()}
                            </div>
                        </div>
                        <div className={styles.logoWrap}>
                            <img src={logoSrc} alt="Logo" className={styles.logo}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const renderBottomSheet = useMemo(() => {
        if (!showBottomSheet) return (<></>);

        return (
            <div className={`${styles.bottomSheet} ${styles.open}`}>
                <div className={styles.bottomSheetContent}>
                    <h2>Change Location</h2>
                    <form onSubmit={handleLocationChange}>
                        <input
                            type="text"
                            value={locationInput}
                            onChange={handleLocationInputChange}
                            placeholder="Enter zip code or city"
                            className={styles.locationInput}
                        />
                        <button type="submit" className={styles.changeLocationButton}>
                            Change Location
                        </button>
                    </form>
                    <button
                        onClick={toggleBottomSheet}
                        className={styles.closeBottomSheetButton}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        );
    }, [showBottomSheet,  handleLocationChange, handleLocationInputChange, toggleBottomSheet]);

    const memoizedRoutes = useMemo(() => (
        <Routes>
            <Route path="/login" element={<Login onLogin={handleLogin}/>}/>
            <Route path="/register" element={<Register onRegister={handleRegister}/>}/>

            <Route element={<ProtectedRoute user={user}/>}>
                <Route path="/search-all" element={<SearchApi handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} geoposition={props.geoposition} />} />
                {props.geoposition && (
                    <Route exact path="/" element={<Results handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} geoposition={props.geoposition}/>}/>
                )}
                {props.geoposition && (
                    <Route path="/search" element={<Results handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} geoposition={props.geoposition}/>}/>
                )}
                <Route path="/business/:id" element={<BusinessProfile handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader}/>}/>

                {/* Location Settings Routes */}
                <Route path="/location-settings/add-deal" element={<DealPostForm handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader}/>}/>
                <Route path="/location-settings/deals/:id/edit-deal" element={<DealPostForm handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader}/>}/>
                <Route path="/location-settings/deals/:business_id/add-deal" element={<DealPostForm handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader}/>}/>

                <Route path="/location-admin" element={<LocationAdminMenu user={user} handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} handleLogout={handleLogout}/>}/>
                <Route path="/location-settings/:id/settings" element={<LocationSettingsMenu handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} handleLogout={handleLogout}/>}/>
                <Route path="/location-settings/:id/deal-manager" element={<DealManager handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader}/>}/>
                <Route path="/location-settings/location-manager" element={<LocationManager handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader}/>}/>
                <Route path="/location-settings/active-deals" element={<ActiveDeals handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader}/>}/>
                <Route path="/location-settings/inactive-deals" element={<InactiveDeals handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader}/>}/>
                <Route path="/location-settings/list-locations" element={<Locations handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader}/>}/>
                <Route path="/location-settings/:id/edit-settings" element={<LocationSettings handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader}/>}/>
                <Route path="/location-settings/user-admin" element={<UserAdminManager handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader}/>}/>
                <Route path="/location-settings/dashboard" element={<LocationDashboard handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader}/>}/>
                <Route path="/location-settings/subscription" element={<SubscriptionSettings handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader}/>}/>

                <Route path="/user-settings" element={<UserSettingsMenu handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} handleLogout={handleLogout}/>}/>
            </Route>

            <Route element={<ProtectedRoute user={user} requiredRole={['admin', 'superadmin']}/>}>
                <Route path="/location-settings/:id/admins" element={<AssignUserToDispensary handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} user={user}/>}/>
                <Route path="/superadmin/manage-dispensary-admins" element={<AssignUserToDispensary handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} user={user}/>}/>
                <Route path="/superadmin" element={<SuperAdminSettingsMenu handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} user={user}/>}/>
                <Route path="/superadmin/manage-dispensaries" element={<ManageDispensaries handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} user={user}/>}/>
                <Route path="/superadmin/manage-users" element={<ManageUsers handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} user={user}/>}/>
            </Route>

            <Route path="*" element={<Navigate to="/" replace/>}/>
        </Routes>

    ), [user, handleLogin, handleRegister, props.geoposition]);


    return (
        <div>
            {showHeader && renderHeader()}
            {showSettingsHeader && renderSettingsHeader()}
            {renderBottomSheet}

            {memoizedRoutes}

            <div className={styles.Navigation}>
                <MainNav user={user} onLogout={handleLogout}/>
            </div>
        </div>
    );
};

export default React.memo(AppRoutes);
