import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import styles from './SuperAdminSettingsMenu.module.css';
import {FaUsers} from "react-icons/fa";
import {FaStore} from "react-icons/fa";
import {FaUserPlus} from "react-icons/fa";
import {FaUserCog} from "react-icons/fa";
import {FaChartBar} from "react-icons/fa";
import {FaCog} from "react-icons/fa";

const SuperAdminSettingsMenu = ({ handleShowHeader, handleShowSettingsHeader, user }) => {
    useEffect(() => {
        handleShowHeader(false);
        handleShowSettingsHeader(true);
    }, [handleShowHeader, handleShowSettingsHeader]);

    return (
        <nav className={styles.superAdminSettingsMenu}>
            <h2 className={styles.title}>Super Admin Settings</h2>
            <ul className={styles.menuList}>
                <div className={styles.section}>

                    <h2 className={styles.sectionHeader}>Manage Users and Dispensaries</h2>

                    {user.role === 'superadmin' && (<>
                            <li className={styles.menuItem}>
                                <Link to="/superadmin/manage-users" className={styles.menuLink}>
                                    <div className={styles.iconAndName}>
                                        <FaUsers/>
                                        Manage Users
                                    </div>
                                    <span className={styles.arrow}>›</span>
                                </Link>
                            </li>
                            <li className={styles.menuItem}>
                                <Link to="/superadmin/manage-dispensaries" className={styles.menuLink}>
                                    <div className={styles.iconAndName}>
                                        <FaStore/>
                                        Manage Dispensaries
                                    </div>
                                    <span className={styles.arrow}>›</span>
                                </Link>
                            </li>
                        </>
                    )}
                    <li className={styles.menuItem}>
                        <Link to="/superadmin/manage-dispensary-admins" className={styles.menuLink}>
                            <div className={styles.iconAndName}>
                                <FaUserPlus/>
                                Assign User to Dispensary
                            </div>
                            <span className={styles.arrow}>›</span>
                        </Link>
                    </li>
                </div>

                {user.role === 'superadmin' && (<>
                <div className={styles.section}>
                    <h2 className={styles.sectionHeader}>System Management</h2>
                    <li className={styles.menuItem}>
                        <Link to="/superadmin/manage-roles" className={styles.menuLink}>
                            <div className={styles.iconAndName}>
                                <FaUserCog/>
                                Manage Roles
                            </div>
                            <span className={styles.arrow}>›</span>
                        </Link>
                    </li>
                    <li className={styles.menuItem}>
                        <Link to="/superadmin/analytics" className={styles.menuLink}>
                            <div className={styles.iconAndName}>
                                <FaChartBar/>
                                System Analytics
                            </div>
                            <span className={styles.arrow}>›</span>
                        </Link>
                    </li>
                    <li className={styles.menuItem}>
                        <Link to="/superadmin/system-settings" className={styles.menuLink}>
                            <div className={styles.iconAndName}>
                                <FaCog/>
                                System Settings
                            </div>
                            <span className={styles.arrow}>›</span>
                        </Link>
                    </li>
                </div>
                </>
                )}
            </ul>
        </nav>
    )
        ;
};

export default SuperAdminSettingsMenu;
