import React, { useState, useEffect, useCallback, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "../App/App.module.css";
import SearchBar from "../SearchBar/SearchBar";
import BusinessList from "../BusinessList/BusinessList";
import MapComponent from "../MapComponent/MapComponent";
import NavbarFilter from "../NavbarFilter/NavbarFilter";

const Results = ({geoposition, handleShowHeader, handleShowSettingsHeader}) => {
    const [businesses, setBusinesses] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const location = useLocation();
    const navigate = useNavigate();
    const MemoizedMapComponent = React.memo(MapComponent);
    const searchBarRef = useRef(null);

    useEffect(() => {
        handleShowHeader(true);
        handleShowSettingsHeader(false);
    }, [handleShowHeader, handleShowSettingsHeader]);

    const handleSearchResults = useCallback((results) => {
        console.log("Search results:", results);
        setBusinesses(results);

        // Update URL
        const params = new URLSearchParams(location.search);
        params.set('results', 'updated');  // We're not storing actual results in URL
        navigate(`?${params.toString()}`, { replace: true });
    }, [location.search, navigate]);

    useEffect(() => {
        if (searchBarRef.current && searchTerm) {
            searchBarRef.current.performSearch();
        }
    }, [geoposition, searchTerm]);

    const handleSearchTermChange = (term) => {
        setSearchTerm(term);
    };

    return (
        <div className={styles.App}>
            <SearchBar
                ref={searchBarRef}
                geoposition={geoposition}
                onSearchResults={handleSearchResults}
                onSearchTermChange={handleSearchTermChange}
            >
                {businesses.length > 0 && (<MemoizedMapComponent businesses={businesses} />)}
            </SearchBar>
            <NavbarFilter />
            <BusinessList businesses={businesses} />
        </div>
    );
}

export default Results;
