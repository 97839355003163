import React, { useState, useEffect } from 'react';
import {Heart, ExternalLink, X} from 'lucide-react';
import styles from './DealList.module.css';
import useApi from "../../utils/useApi";
import { IoArrowUpCircleOutline } from "react-icons/io5";
import {TbHeart, TbHeartFilled} from "react-icons/tb";

const BottomSheet = ({ isOpen, onClose, product, deal }) => {
    const [isClosing, setIsClosing] = useState(false);

    useEffect(() => {
        if (!isOpen) {
            setIsClosing(false);
        }
    }, [isOpen]);

    const handleClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            onClose();
        }, 300);
    };

    if (!isOpen && !isClosing) return null;

    return (
        <div
            className={`${styles.bottomSheetOverlay} ${isClosing ? styles.closing : ''}`}
            onClick={handleClose}
        >
            <div
                className={`${styles.bottomSheetContent} ${isOpen ? styles.open : ''} ${isClosing ? styles.closing : ''}`}
                onClick={(e) => e.stopPropagation()}
            >
                <button className={styles.bottomSheetCloseButton} onClick={handleClose}>
                    <X size={24} />
                </button>

                <img src={deal.imageUrl} alt={deal.title} className={styles.bottomSheetDealImage} />

                <div className={styles.bottomSheetDealInfo}>
                    <h2>{deal.title}</h2>
                    <p>{deal.description}</p>
                    <p>Discount: {deal.discountLabel}</p>
                    <p>Valid until: {new Date(deal.endsAt).toLocaleDateString()}</p>
                    {deal.finePrint && <p className={styles.bottomSheetFinePrint}>{deal.finePrint}</p>}
                </div>

                <div className={styles.bottomSheetProductInfo}>
                    <div className={styles.bottomSheetProductImageWrapper}>
                        <img src={product.formatted_thumbnail_url} alt={product.name} className={styles.bottomSheetProductImage} />
                        <div className={styles.bottomSheetProductDetails}>
                            <h3>{product.name}</h3>
                            <p className={styles.bottomSheetProductPrice}>
                                {product.salePrice ? (
                                    <>
                                        <span className={styles.bottomSheetOriginalPrice}>${product.price.toFixed(2)}</span>
                                        <span className={styles.bottomSheetSalePrice}>{product.salePrice}</span>
                                    </>
                                ) : (
                                     `$${product.price.toFixed(2)}`
                                 )}
                            </p>
                        </div>
                    </div>
                    <p>{product.description}</p>
                </div>
            </div>
        </div>
    );
};

const ProductCard = ({ product, deal }) => {
    const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
    const { likeProduct } = useApi();

    const handleLike = async () => {
        try {
            const response = await likeProduct(product.id);
            product.is_liked = response.like;
        } catch (error) {
            console.error("Failed to toggle the like status:", error);
        }
    };

    const openBottomSheet = () => {
        setIsBottomSheetOpen(true);
    };

    const closeBottomSheet = () => {
        setIsBottomSheetOpen(false);
    };

    return (
        <>
            <div className={styles.productCardWrap} onClick={openBottomSheet}>
                <div className={styles.productCard}>
                    <div
                        className={styles.productImage}
                        style={{ backgroundImage: `url(${product.formatted_thumbnail_url})` }}
                    >
                        {deal.discountLabel && (
                            <div className={styles.discountLabel}>{deal.discountLabel}</div>
                        )}
                        <div className={styles.productActions}>
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleLike();
                                }}
                                className={styles.likeButton}
                            >
                                {product.is_liked ? (
                                    <TbHeartFilled size={15} className={styles.liked} />
                                ) : (
                                     <TbHeart size={15} />
                                 )}
                            </button>
                            <a
                                href={deal.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={styles.dealLink}
                                onClick={(e) => e.stopPropagation()}
                            >
                                <IoArrowUpCircleOutline size={18} />
                            </a>
                        </div>
                    </div>
                </div>
                <div className={styles.productInfo}>
                    <h3 className={styles.productName}>{deal.label} {product.name}</h3>
                    {deal.title.length > 0 && (<p className={styles.dealTitle}>{deal.title}</p>)}
                    <p className={styles.priceStrikethrough}>
                        {product.salePrice ? "$" + product.price.toFixed(2) : ''}
                    </p>
                    <p className={styles.productPrice}>
                        {product.salePrice ? product.salePrice : "$" + product.price.toFixed(2)}
                    </p>
                </div>
            </div>
            <BottomSheet
                isOpen={isBottomSheetOpen}
                onClose={closeBottomSheet}
                product={product}
                deal={deal}
            />
        </>
    );
};

const CategoryDeals = ({ category, products }) => {
    return (
        <div className={styles.categorySection}>
            <h2 className={styles.categoryTitle}>{category} Deals</h2>
            <div className={styles.productsScroller}>
                {products.map((product) => (
                    <ProductCard key={product.id} product={product} deal={product.product_deal[0]} />
                ))}
            </div>
        </div>
    );
};

const DealList = ({ deals }) => {
    return (
        <div className={styles.dealsContainer}>
            {Object.entries(deals).map(([category, { data }]) => (
                <CategoryDeals key={category} category={category} products={data} />
            ))}
        </div>
    );
};

export default DealList;
