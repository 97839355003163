const GeolocationManager = {
    // Key for storing in localStorage
    STORAGE_KEY: 'userGeolocation',

    // Time (in milliseconds) after which the stored location is considered stale
    STALE_TIME: 30 * 60 * 1000, // 30 minutes

    // Get current position and store it
    getCurrentPosition() {
        return new Promise((resolve, reject) => {
            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const locationData = {
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude,
                            timestamp: new Date().getTime()
                        };
                        console.log(locationData);
                        localStorage.setItem(this.STORAGE_KEY, JSON.stringify(locationData));
                        resolve(locationData);
                    },
                    (error) => {
                        console.error("Error getting location:", error.message);
                        reject(error);
                    }
                );
            } else {
                const error = new Error("Geolocation is not supported by this browser.");
                console.error(error.message);
                reject(error);
            }
        });
    },

    // Get stored location
    getStoredLocation() {
        const storedData = localStorage.getItem(this.STORAGE_KEY);
        return storedData ? JSON.parse(storedData) : null;
    },

    // Check if stored location is stale
    isLocationStale() {
        const storedLocation = this.getStoredLocation();
        if (!storedLocation) return true;

        const now = new Date().getTime();
        return (now - storedLocation.timestamp) > this.STALE_TIME;
    },

    // Get location (from storage if fresh, or get new location if stale)
    getLocation() {
        return new Promise((resolve, reject) => {
            if (!this.isLocationStale()) {
                resolve(this.getStoredLocation());
            } else {
                this.getCurrentPosition().then(resolve).catch(reject);
            }
        });
    }
};

export default GeolocationManager;
