import React from 'react';
import { Home, Search, MessageCircle, MoreHorizontal } from 'lucide-react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import VoiceGPTButton from '../VoiceGPT/VoiceGPTButton';
import { useVoiceSearch } from '../VoiceGPT/VoiceSearchContext';

const MainNav = ({ user }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const isSuperAdmin = user && (user.role === 'superadmin');
    const { setTranscript, setShouldSearch } = useVoiceSearch();

    const navItems = [
        { to: "/", icon: <Home />, label: "Home" },
        { to: "/search-all", icon: <Search />, label: "Search" },
        { to: "/messages", icon: <MessageCircle />, label: "Message" },
        { to: user?.role === 'user' ? "/user-settings" : "/location-admin", icon: <MoreHorizontal />, label: "More" },
    ];

    const handleSearchPerformed = (query) => {
        setTranscript(query);
        setShouldSearch(true);
        navigate(`/search-all?q=${encodeURIComponent(query)}`);
    };

    return (
        <div style={{
            backgroundColor: 'rgba(0,0,0,0.9)',
            borderRadius: '30px',
            padding: '10px 15px',
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            position: 'fixed',
            bottom: '9px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '90%',
            maxWidth: isSuperAdmin ? '500px' : '400px',
            zIndex: 10,
            height: 45
        }}>
            {navItems.slice(0, Math.ceil(navItems.length / 2)).map((item, index) => (
                <Link key={index} to={item.to}>
                    <NavItem
                        icon={item.icon}
                        label={item.label}
                        active={item.to === '/' ? location.pathname === '/' : location.pathname.startsWith(item.to)}
                    />
                </Link>
            ))}
            <VoiceGPTButton onSearchPerformed={handleSearchPerformed} />
            {navItems.slice(Math.ceil(navItems.length / 2)).map((item, index) => (
                <Link key={index + Math.ceil(navItems.length / 2)} to={item.to}>
                    <NavItem
                        icon={item.icon}
                        label={item.label}
                        active={item.to === '/' ? location.pathname === '/' : location.pathname.startsWith(item.to)}
                    />
                </Link>
            ))}
        </div>
    );
};

const NavItem = ({ icon, label, active }) => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color: active ? '#3478F6' : '#8E8E93',
        }}>
            {React.cloneElement(icon, { size: 24 })}
            <span style={{ fontSize: '8px', marginTop: '4px' }}>{label}</span>
            {active && (
                <div style={{
                    width: '4px',
                    height: '4px',
                    borderRadius: '50%',
                    backgroundColor: '#3478F6',
                    marginTop: '4px',
                }} />
            )}
        </div>
    );
};

export default MainNav;
