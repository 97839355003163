import React, { useState, useEffect, useCallback, useRef } from "react";
import styles from "./App.module.css";
import { ThemeProvider } from "../ThemeContext/ThemeContext";
import AppRouter from "../AppRouter/AppRouter";
import useApi from "../../utils/useApi";
import GeolocationManager from "../../utils/geolocation";
import authApi from "../../utils/auth.api";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error("Uncaught error:", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return <h1>Something went wrong.</h1>;
        }

        return this.props.children;
    }
}

const stateProvinceAbbreviations = {
    // United States
    'Alabama': 'AL', 'Alaska': 'AK', 'Arizona': 'AZ', 'Arkansas': 'AR', 'California': 'CA',
    'Colorado': 'CO', 'Connecticut': 'CT', 'Delaware': 'DE', 'Florida': 'FL', 'Georgia': 'GA',
    'Hawaii': 'HI', 'Idaho': 'ID', 'Illinois': 'IL', 'Indiana': 'IN', 'Iowa': 'IA',
    'Kansas': 'KS', 'Kentucky': 'KY', 'Louisiana': 'LA', 'Maine': 'ME', 'Maryland': 'MD',
    'Massachusetts': 'MA', 'Michigan': 'MI', 'Minnesota': 'MN', 'Mississippi': 'MS', 'Missouri': 'MO',
    'Montana': 'MT', 'Nebraska': 'NE', 'Nevada': 'NV', 'New Hampshire': 'NH', 'New Jersey': 'NJ',
    'New Mexico': 'NM', 'New York': 'NY', 'North Carolina': 'NC', 'North Dakota': 'ND', 'Ohio': 'OH',
    'Oklahoma': 'OK', 'Oregon': 'OR', 'Pennsylvania': 'PA', 'Rhode Island': 'RI', 'South Carolina': 'SC',
    'South Dakota': 'SD', 'Tennessee': 'TN', 'Texas': 'TX', 'Utah': 'UT', 'Vermont': 'VT',
    'Virginia': 'VA', 'Washington': 'WA', 'West Virginia': 'WV', 'Wisconsin': 'WI', 'Wyoming': 'WY',

    // Canada
    'Alberta': 'AB', 'British Columbia': 'BC', 'Manitoba': 'MB', 'New Brunswick': 'NB',
    'Newfoundland and Labrador': 'NL', 'Northwest Territories': 'NT', 'Nova Scotia': 'NS',
    'Nunavut': 'NU', 'Ontario': 'ON', 'Prince Edward Island': 'PE', 'Quebec': 'QC',
    'Saskatchewan': 'SK', 'Yukon': 'YT'
};

const App = () => {
    const [user, setUser] = useState(null);
    const [geoposition, setGeoposition] = useState(null);
    const { getGeoposition, isLoading, error } = useApi();
    const locationChangeTimeoutRef = useRef(null);

    const changeLocation = useCallback(async (input) => {
        console.log("Changing location to:", input);

        // Clear any existing timeout
        if (locationChangeTimeoutRef.current) {
            clearTimeout(locationChangeTimeoutRef.current);
        }

        // Set a new timeout
        locationChangeTimeoutRef.current = setTimeout(async () => {
            try {
                const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(input)}&limit=1`);
                const data = await response.json();

                console.log("API response:", data);
                if (data.length > 0) {
                    const location = data[0];
                    const addressParts = location.display_name.split(', ');
                    const newGeo = {
                        latitude: parseFloat(location.lat),
                        longitude: parseFloat(location.lon),
                        city: location.name,
                        state: addressParts[addressParts.length - 2],
                        state_abbr: stateProvinceAbbreviations[addressParts[addressParts.length - 2]] || addressParts[addressParts.length - 2],
                        country: addressParts[addressParts.length - 1],
                        country_code: addressParts[addressParts.length - 1] === "United States" ? "US" : "Unknown",
                    };

                    console.log("New geoposition:", newGeo);
                    setGeoposition(newGeo);
                    localStorage.setItem('geoposition', JSON.stringify(newGeo));
                    console.log("Location updated successfully");
                } else {
                    console.error("Location not found");
                }
            } catch (error) {
                console.error("Error changing location:", error);
            }
        }, 1000);  // 1000ms debounce time
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const currentUser = authApi.getCurrentUser();
                if (currentUser) {
                    setUser(currentUser);
                }

                const savedGeoposition = localStorage.getItem('geoposition');
                if (savedGeoposition) {
                    console.log("Using saved geoposition:", savedGeoposition);
                    setGeoposition(JSON.parse(savedGeoposition));
                } else {
                    const results = await getGeoposition();
                    let allGeo = results;

                    const geo = GeolocationManager;
                    const locationData = await geo.getLocation();
                    allGeo.latitude = locationData.latitude;
                    allGeo.longitude = locationData.longitude;

                    const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${locationData.latitude}&lon=${locationData.longitude}`);
                    const data = await response.json();
                    allGeo.city = data.address.city || data.address.town || data.address.village;
                    allGeo.state = data.address.state;
                    allGeo.state_abbr = stateProvinceAbbreviations[data.address.state] || data.address.state;
                    allGeo.country = data.address.country;
                    allGeo.country_code = data.address.country_code.toUpperCase();

                    setGeoposition(allGeo);
                    localStorage.setItem('geoposition', JSON.stringify(allGeo));
                }
            } catch (error) {
                console.error("Error in fetchData:", error);
            }
        };

        fetchData();
    }, [getGeoposition]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <ErrorBoundary>
            <ThemeProvider>
                <div className={styles.App}>
                    {geoposition ? (
                        <AppRouter
                            user={user}
                            geoposition={geoposition}
                            changeLocation={changeLocation}
                        />
                    ) : (
                         <div>Loading geoposition...</div>
                     )}
                </div>
            </ThemeProvider>
        </ErrorBoundary>
    );
};

export default App;
