import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './Login.module.css';
import bootstrapStyles from './LoginBootstrap.module.css';

const Login = ({ onLogin }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        onLogin(email, password);
    };

    return (
        <div className={`${bootstrapStyles.container} ${styles.loginContainer}`}>
            <div className={`${bootstrapStyles.card} ${styles.loginCard}`}>
                <div className={bootstrapStyles['card-body']}>
                    <h2 className={`${bootstrapStyles['card-title']} ${bootstrapStyles['text-center']} ${styles.loginTitle}`}>Login</h2>
                    <form onSubmit={handleSubmit}>
                        <div className={bootstrapStyles['mb-3']}>
                            <label htmlFor="email" className={bootstrapStyles['form-label']}>Email address</label>
                            <input
                                type="email"
                                className={bootstrapStyles['form-control']}
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className={bootstrapStyles['mb-3']}>
                            <label htmlFor="password" className={bootstrapStyles['form-label']}>Password</label>
                            <input
                                type="password"
                                className={bootstrapStyles['form-control']}
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>
                        <div className={bootstrapStyles['d-grid']}>
                            <button type="submit" className={`${bootstrapStyles.btn} ${bootstrapStyles['btn-primary']} ${styles.loginButton}`}>Login</button>
                        </div>
                    </form>
                    <div className={`${bootstrapStyles['text-center']} ${bootstrapStyles['mt-3']} ${styles.registerLink}`}>
                        Don't have an account? <Link to="/register">Register here</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
