import React from 'react';
import styles from './ProductList.module.css';
import {Route, Link} from "react-router-dom";


const ProductList = ({ products }) => {
    return (
        <div className={styles.productList}>
            {products.map((product) => (
                <Link to={`/business/${product.dispensary_id}`}>

                    <div key={product.id} className={styles.productItem}>
                        <div className={styles.productImage}>
                            <img src={product.formatted_thumbnail_url} alt={product.name}/>
                        </div>
                        <div className={styles.productInfo}>
                            <h3 className={styles.productName}>{product.name}</h3>
                            <p className={styles.productCategory}>{product.product_category}</p>
                            <div className={styles.productMetadata}>
                                {product.brand && (
                                    <span className={styles.brandName}>{product.brand.name}</span>
                                )}

                            </div>
                            <div className={styles.productMetadata}>
                                {product.dispensary && (
                                    <span className={styles.dispensaryName}>{product.dispensary.name}</span>
                                )}
                                {product.dispensary && (
                                    <span className={styles.dispensaryName}>{product.dispensary.city}, {product.dispensary.state}</span>
                                )}
                                {product.distance_mi !== undefined && (
                                    <span className={styles.dispensaryName}>
                                        {product.distance_mi.toFixed(1)} mi
                                    </span>
                                )}
                            </div>
                            <div className={styles.priceInfo}>
                                {product.salePrice ? (
                                    <>
                                        <span className={styles.originalPrice}>${product.price}</span>
                                        <span className={styles.salePrice}>{product.salePrice}</span>
                                    </>
                                ) : (
                                     <span className={styles.price}>${product.price}</span>
                                 )}
                            </div>
                        </div>
                    </div>
                </Link>

            ))}
        </div>
    );
};

export default ProductList;
