import React, { createContext, useState, useContext, useCallback } from 'react';

const VoiceSearchContext = createContext();

export const VoiceSearchProvider = ({ children }) => {
    const [isListening, setIsListening] = useState(false);
    const [transcript, setTranscript] = useState('');
    const [error, setError] = useState(null);
    const [shouldSearch, setShouldSearch] = useState(false);

    const startListening = useCallback(() => {
        setIsListening(true);
        setTranscript('');
        setError(null);
        setShouldSearch(false);
    }, []);

    const stopListening = useCallback(() => {
        setIsListening(false);
    }, []);

    const resetSearch = useCallback(() => {
        setShouldSearch(false);
    }, []);

    const contextValue = {
        isListening,
        transcript,
        error,
        shouldSearch,
        startListening,
        stopListening,
        setTranscript,
        setError,
        setShouldSearch,
        resetSearch
    };

    return (
        <VoiceSearchContext.Provider value={contextValue}>
            {children}
        </VoiceSearchContext.Provider>
    );
};

export const useVoiceSearch = () => {
    const context = useContext(VoiceSearchContext);
    if (!context) {
        throw new Error('useVoiceSearch must be used within a VoiceSearchProvider');
    }
    return context;
};
