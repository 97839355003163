import React, { useState } from 'react';
import styles from './MenuView.module.css';

const MenuView = ({ menuItems }) => {
    const [selectedCategory, setSelectedCategory] = useState(Object.keys(menuItems)[0]);

    return (
        <div className={styles.menuContainer}>
            <div className={styles.categoryNav}>
                {Object.keys(menuItems).map(category => (
                    <button
                        key={category}
                        onClick={() => setSelectedCategory(category)}
                        className={selectedCategory === category ? styles.activeCategory : ''}
                    >
                        {category}
                    </button>
                ))}
            </div>
            <div className={styles.productsGrid}>
                {selectedCategory && menuItems[selectedCategory] && menuItems[selectedCategory].data && (
                    <div className={styles.productsList}>
                        {[menuItems[selectedCategory].data].flat().map(product => (
                            <div key={product.id} className={styles.productCard}>
                                <img src={product.formatted_thumbnail_url} alt={product.name} className={styles.productImage} />
                                <h3>{product.name}</h3>
                                <p>{product.price_includes_tax ? `$${product.price}` : `$${product.price} + tax`}</p>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default MenuView;
