import React, { memo } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoute = memo(({ user, requiredRole }) => {
    const hasRequiredRole = () => {
        if (!user || !user.role) return false;
        if (Array.isArray(requiredRole)) {
            return requiredRole.includes(user.role) || user.role === 'superadmin';
        }
        return user.role === requiredRole || user.role === 'superadmin';
    };

    if (!user) {
        return <Navigate to="/login" replace />;
    }

    if (requiredRole && !hasRequiredRole()) {
        return <Navigate to="/unauthorized" replace />;
    }

    return <Outlet />;
});

export default ProtectedRoute;
