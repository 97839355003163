import React, {useState, useCallback, useEffect} from "react";
import styles from "./IconSquare.module.css";


const IconSquare = ({children, label, customClass}) => {
    return (
        <div className={customClass ? customClass:styles.iconSquare}>
            {children}

            {label && <p>{label}</p>}
        </div>
    )
}

export default IconSquare;
