import React, { createContext, useState, useEffect, useContext } from 'react';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
    const [isDarkMode, setIsDarkMode] = useState(false);

    useEffect(() => {
        const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
        setIsDarkMode(prefersDarkMode);
    }, []);

    useEffect(() => {
        const root = document.documentElement;
        if (isDarkMode) {
            root.style.setProperty('--background-accent-color', 'var(--dark-black-background)');
            root.style.setProperty('--background-color', 'var(--dark-background)');
            root.style.setProperty('--text-color', 'var(--dark-text)');
            root.style.setProperty('--primary-color', 'var(--dark-primary)');
            root.style.setProperty('--secondary-color', 'var(--dark-secondary)');
            root.style.setProperty('--thirdary-color', 'var(--dark-thirdary)');
            root.style.setProperty('--accent-color', 'var(--dark-accent)');
            root.style.setProperty('--icon-transparent', 'var(--dark-icon-transparent)');
        } else {
            root.style.setProperty('--background-accent-color', 'var(--light-accent-background)');
            root.style.setProperty('--background-color', 'var(--light-background)');
            root.style.setProperty('--text-color', 'var(--light-text)');
            root.style.setProperty('--primary-color', 'var(--light-primary)');
            root.style.setProperty('--secondary-color', 'var(--light-secondary)');
            root.style.setProperty('--thirdary-color', 'var(--light-thirdary)');
            root.style.setProperty('--accent-color', 'var(--light-accent)');
            root.style.setProperty('--icon-transparent', 'var(--light-icon-transparent)');

        }
    }, [isDarkMode]);

    const toggleTheme = () => {
        setIsDarkMode(!isDarkMode);
    };

    return (
        <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};

export const useTheme = () => useContext(ThemeContext);
