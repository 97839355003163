import React from 'react';
import styles from './DealPills.module.css';

const DealPills = ({ deals }) => {
    return (
        <div className={styles.pillContainer}>
            {deals.map((deal) => (
                <div key={deal.id} className={styles.pill}>
                    <span className={styles.title}>{deal.label} {deal.title}</span>
                </div>
            ))}
        </div>
    );
};

export default DealPills;
