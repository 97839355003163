import React, { useEffect, useRef, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import styles from './MapComponent.module.css';
import restaurantIcon from './icons/mapicon.svg';
import cafeIcon from './icons/mapicon.svg';
import barIcon from './icons/mapicon.svg';
import './map.css';
import { useTheme } from '../ThemeContext/ThemeContext'; // Ensure this path is correct

const icons = {
    restaurant: restaurantIcon,
    cafe: cafeIcon,
    bar: barIcon,
};

// Custom dark mode style for Google Maps
const darkModeStyle = [
    { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
    {
        featureType: "administrative.locality",
        elementType: "labels.text.fill",
        stylers: [{ color: "#d59563" }],
    },
    {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [{ color: "#d59563" }],
    },
    {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [{ color: "#263c3f" }],
    },
    {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [{ color: "#6b9a76" }],
    },
    {
        featureType: "road",
        elementType: "geometry",
        stylers: [{ color: "#38414e" }],
    },
    {
        featureType: "road",
        elementType: "geometry.stroke",
        stylers: [{ color: "#212a37" }],
    },
    {
        featureType: "road",
        elementType: "labels.text.fill",
        stylers: [{ color: "#9ca5b3" }],
    },
    {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [{ color: "#746855" }],
    },
    {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [{ color: "#1f2835" }],
    },
    {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [{ color: "#f3d19c" }],
    },
    {
        featureType: "transit",
        elementType: "geometry",
        stylers: [{ color: "#2f3948" }],
    },
    {
        featureType: "transit.station",
        elementType: "labels.text.fill",
        stylers: [{ color: "#d59563" }],
    },
    {
        featureType: "water",
        elementType: "geometry",
        stylers: [{ color: "#17263c" }],
    },
    {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [{ color: "#515c6d" }],
    },
    {
        featureType: "water",
        elementType: "labels.text.stroke",
        stylers: [{ color: "#17263c" }],
    },
];

const MapComponent = ({ businesses, children, customClass }) => {
    const mapRef = useRef(null);
    const [map, setMap] = useState(null);
    const [markers, setMarkers] = useState([]);
    const { isDarkMode } = useTheme();

    useEffect(() => {
        const loader = new Loader({
                                      apiKey: "AIzaSyD9tl_iP7Z3LTvulEgqipVlcu-EJfDeARI",
                                      version: "weekly",
                                  });

        loader.load().then(() => {
            const google = window.google;
            const mapInstance = new google.maps.Map(mapRef.current, {
                center: { lat: 0, lng: 0 },
                zoom: 2, // Default zoom level
                styles: isDarkMode ? darkModeStyle : [], // Use dark mode style if isDarkMode is true, otherwise use default
                zoomControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false
            });

            setMap(mapInstance);
        });
    }, [isDarkMode]); // Re-initialize map when theme changes

    useEffect(() => {
        if (map && businesses.length > 0) {
            const google = window.google;
            const bounds = new google.maps.LatLngBounds();
            const newMarkers = [];

            businesses.forEach((business) => {
                const position = new google.maps.LatLng(
                    business.lat ?? business.latitude,
                    business.lon ?? business.longitude
                );

                const marker = new google.maps.Marker({
                                                          position: position,
                                                          map: map,
                                                          title: business.name,
                                                          icon: business.avatar_image?.map_marker
                                                                ? {
                                                                  url: business.avatar_image.map_marker,
                                                                  scaledSize: new google.maps.Size(35, 41),
                                                                  anchor: new google.maps.Point(12, 41),
                                                              }
                                                                : {
                                                                  url: (icons[business.type] || icons.restaurant),
                                                                  scaledSize: new google.maps.Size(35, 41),
                                                                  anchor: new google.maps.Point(12, 41),
                                                              },
                                                      });

                const infoWindow = new google.maps.InfoWindow({
                                                                  content: `
                        <div style="color: ${isDarkMode ? '#ffffff' : '#000000'}; background-color: ${isDarkMode ? '#2c2c2e' : '#ffffff'}; padding: 10px; border-radius: 5px;">
                            <h3 style="margin: 0 0 5px; color: ${isDarkMode ? '#ffffff' : '#000000'};">${business.name}</h3>
                            <p style="margin: 0 0 5px; color: ${isDarkMode ? '#8e8e93' : '#666666'};">${business.address}</p>
                            <p style="margin: 0; color: ${isDarkMode ? '#8e8e93' : '#666666'};">Rating: ${business.rating}</p>
                        </div>
                    `,
                                                              });

                marker.addListener("click", () => {
                    infoWindow.open(map, marker);
                });

                bounds.extend(position);
                newMarkers.push(marker);
            });

            setMarkers(newMarkers);

            if (businesses.length === 1) {
                // For a single location, center the map and set a fixed zoom level
                map.setCenter(newMarkers[0].getPosition());
                map.setZoom(13); // Adjust this value to zoom out more or less
            } else {
                // For multiple locations, fit bounds as before
                map.fitBounds(bounds);
            }
        }
    }, [map, businesses, isDarkMode]);

    // Clean up markers when component unmounts or businesses change
    useEffect(() => {
        return () => {
            markers.forEach(marker => marker.setMap(null));
        };
    }, [markers]);

    return (
        <div className={customClass ?? styles.MapContainer}>
            <div
                ref={mapRef}
                className={customClass ?? styles.MapContainer}
                style={{ height: '400px', width: '100%' }}
            >
            </div>
            {children}

        </div>

    );
};

export default MapComponent;
