// UserAdminManager.js
import React from 'react';

const UserAdminManager = () => {
    return (
        <div>
            <h2>User Admin Manager</h2>
            {/* Add user management functionality for the location */}
        </div>
    );
};

export default UserAdminManager;

