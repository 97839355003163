import React, { useState } from 'react';
import { Heart, ExternalLink } from 'lucide-react';
import styles from './DealListAdmin.module.css';
import { IoArrowUpCircleOutline } from "react-icons/io5";
import {Route, Link} from "react-router-dom";


const DealCardAdmin = ({ deal }) => {
    const [isLiked, setIsLiked] = useState(false);

    return (
        <div className={styles.dealWrap}>
            <div className={styles.dealCardWrap}>
                <div className={styles.dealCard} style={{ backgroundImage: `url(${deal.imageUrl})`, backgroundSize: 'cover', backgroundPosition: 'center center' }}>
                    <div className={styles.dealContent}>

                        <div className={styles.dealFooter}>

                            <div className={styles.dealActions}>
                                <button
                                    onClick={() => setIsLiked(!isLiked)}
                                    className={`${styles.likeButton} ${isLiked ? styles.likeButtonActive : ''}`}
                                >
                                    <Heart size={15} className={isLiked ? 'fill-current' : ''}/>
                                </button>
                                <a
                                    href={deal.deal_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={styles.redeemButton}
                                >
                                    <IoArrowUpCircleOutline size={18}/>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
                <div className={styles.editDeal}>
                    <Link to={`/location-settings/deals/${deal.id}/edit-deal`} className={styles.adminButton}>
                        Edit
                    </Link>
                </div>
            </div>

            <p className={styles.dealDescription}>{deal.caption}</p>
            <p className={styles.dealDescription}>{deal.terms}</p>
            <div className={styles.dealDays}>
                {deal.daysOfWeek && (<p>Valid: {deal.daysOfWeek.join(', ')}</p>)}

            </div>


        </div>

    );
};

const DealListAdmin = ({ deals }) => {
    return (
        <div className={styles.dealGrid}>
            {deals.map((deal) => (
                <DealCardAdmin key={deal.id} deal={deal}/>
            ))}

            {deals.length <= 0 && (<div>No Deals Yet</div>)}
        </div>
    );
};

export default DealListAdmin;
