import React, { useEffect, useState, useMemo } from 'react';
import styles from "../Settings.module.css";
import useApi from '../../../utils/useApi';
import BusinessList from '../../BusinessList/BusinessList';
import BusinessListAdmin from "../../BusinessList/BusinessListAdmin";

const LocationManager = () => {
    const [locations, setLocations] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const api = useApi();

    const memoizedApi = useMemo(() => ({
        searchDispensaries: api.searchDispensaries
    }), []);

    useEffect(() => {
        const fetchLocations = async () => {
            try {
                let mine = true;
                setIsLoading(true);
                const data = await memoizedApi.searchDispensaries(false, mine);
                setLocations(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchLocations();
    }, [memoizedApi]);

    if (isLoading) return <div className={styles.container}>Loading locations...</div>;
    if (error) return <div className={styles.container}>Error loading locations: {error}</div>;

    return (
        <div className={styles.container}>

            {locations.length > 0 ? (
                <BusinessListAdmin businesses={locations} />
            ) : (
                 <p>No locations found.</p>
             )}
        </div>
    );
};

export default LocationManager;
