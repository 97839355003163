import React from 'react';
import styles from './Loader.module.css';

const LoaderCard = () => (
    <div className={styles.loaderCard}>
        <div className={styles.cardWrapper}>
            <div className={`${styles.imagePlaceholder} ${styles.animatePulse}`}></div>
            <div className={styles.cardContent}>
                <div className={`${styles.textPlaceholder} ${styles.animatePulse}`}></div>
                <div className={`${styles.textPlaceholder} ${styles.animatePulse} ${styles.shortPlaceholder}`}></div>
            </div>
        </div>
    </div>
);

const CategoryLoader = () => (
    <div className={styles.categoryLoader}>
        <div className={`${styles.categoryTitlePlaceholder} ${styles.animatePulse}`}></div>
        <div className={styles.cardsContainer}>
            {[...Array(10)].map((_, i) => (
                <LoaderCard key={i} />
            ))}
        </div>
    </div>
);

const ProductLoader = () => (
    <div className={styles.productLoader}>
        {[...Array(5)].map((_, i) => (
            <CategoryLoader key={i} />
        ))}
    </div>
);

export default ProductLoader;


