import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import AppRoutes from './AppRoutes';
import {VoiceSearchProvider} from "../VoiceGPT/VoiceSearchContext";

const AppRouter = (props) => {
    return (
        <Router>
            <VoiceSearchProvider>
                <AppRoutes {...props} />
            </VoiceSearchProvider>
        </Router>
    );
};

export default AppRouter;
