import React, { useState } from 'react';
import styles from './NavbarFilter.module.css';
import MenuIcon from "../Icons/MenuIcon/MenuIcon";

const NavbarFilter = () => {
    const [activeFilter, setActiveFilter] = useState('Flower');

    const filters = ['Flower', 'Vapes', 'Pre-Rolls', 'Edibles', 'Topicals', 'Concentrates', 'Accessories'];

    return (
        <nav className={styles.navbar}>
            <div className={styles.menuBtn}>
                <MenuIcon />
            </div>
            <div className={styles.filterListContainer}>
                <ul className={styles.filterList}>
                    {filters.map((filter, index) => (
                        <li
                            key={filter}
                            className={`
                                ${styles.filterItem}
                                ${activeFilter === filter ? styles.active : styles.inactive}
                                ${index === filters.length - 1 ? styles.lastItem : ''}
                            `}
                        >
                            <button
                                className={styles.button}
                                onClick={() => setActiveFilter(filter)}
                            >
                                {filter}
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
        </nav>
    );
};

export default NavbarFilter;
