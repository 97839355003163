import React from 'react';

const StarRating = ({ rating, maxRating = 5, size = 24, fillColor = '#98fb98', backgroundColor = '#E5E7EB' }) => {
    const Star = ({ fillPercentage }) => (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <linearGradient id={`starGradient-${fillPercentage}`}>
                    <stop offset={`${fillPercentage}%`} stopColor={fillColor} />
                    <stop offset={`${fillPercentage}%`} stopColor={backgroundColor} />
                </linearGradient>
            </defs>
            <path
                d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z"
                fill={`url(#starGradient-${fillPercentage})`}
            />
        </svg>
    );

    return (
        <div style={{ display: 'inline-flex' }}>
            {[...Array(5)].map((_, index) => {
                const fillPercentage = Math.max(0, Math.min(100, (rating - index) * 100));
                return <Star key={index} fillPercentage={fillPercentage} />;
            })}
        </div>
    );
};

export default StarRating;
