import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import styles from './LocationSettingsMenu.module.css';
import { IoTicketOutline, IoBatteryChargingOutline, IoBatteryDeadOutline } from "react-icons/io5";
import { TbSettings } from "react-icons/tb";
import { MdManageAccounts } from "react-icons/md";
import { SiChartdotjs } from "react-icons/si";
import useApi from '../../utils/useApi'; // Adjust the import path as needed

const LocationSettingsMenu = ({ handleShowHeader, handleShowSettingsHeader, handleLogout }) => {
    const { id } = useParams();
    const [locationName, setLocationName] = useState('');
    const { getBusiness } = useApi();

    useEffect(() => {
        handleShowHeader(false);
        handleShowSettingsHeader(true);

        const fetchLocationDetails = async () => {
            if (id) {
                try {
                    const locationData = await getBusiness(id);
                    setLocationName(locationData.name);
                } catch (error) {
                    console.error("Failed to fetch location details:", error);
                    setLocationName('Unknown Location');
                }
            }
        };

        fetchLocationDetails();
    }, [handleShowHeader, handleShowSettingsHeader, id, getBusiness]);

    return (
        <nav className={styles.locationSettingsMenu}>
            <h2 className={styles.title}>{locationName ? `${locationName} Settings` : 'Settings'}</h2>
            <ul className={styles.menuList}>
                <div className={styles.section}>
                    <h2 className={styles.sectionHeader}>Manage your deals</h2>
                    <li className={styles.menuItem}>
                        <Link to={`/location-settings/deals/${id}/add-deal`} className={styles.menuLink}>
                            <div className={styles.iconAndName}>
                                <IoTicketOutline/>
                                Add Deal
                            </div>
                            <span className={styles.arrow}>›</span>
                        </Link>
                    </li>
                    <li className={styles.menuItem}>
                        <Link to={`/location-settings/${id}/active-deals`} className={styles.menuLink}>
                            <div className={styles.iconAndName}>
                                <IoBatteryChargingOutline/>
                                Active Deals
                            </div>
                            <span className={styles.arrow}>›</span>
                        </Link>
                    </li>
                    <li className={styles.menuItem}>
                        <Link to={`/location-settings/${id}/inactive-deals`} className={styles.menuLink}>
                            <div className={styles.iconAndName}>
                                <IoBatteryDeadOutline/>
                                Inactive Deals
                            </div>
                            <span className={styles.arrow}>›</span>
                        </Link>
                    </li>
                </div>

                <div className={styles.section}>
                    <h2 className={styles.sectionHeader}>Set your location settings</h2>
                    <li className={styles.menuItem}>
                        <Link to={`/location-settings/${id}/edit-settings`} className={styles.menuLink}>
                            <div className={styles.iconAndName}>
                                <TbSettings/>
                                Location Settings
                            </div>
                            <span className={styles.arrow}>›</span>
                        </Link>
                    </li>
                    <li className={styles.menuItem}>
                        <Link to={`/location-settings/${id}/admins`} className={styles.menuLink}>
                            <div className={styles.iconAndName}>
                                <MdManageAccounts/>
                                User Admin Manager
                            </div>
                            <span className={styles.arrow}>›</span>
                        </Link>
                    </li>
                    <li className={styles.menuItem}>
                        <Link to={`/location-settings/${id}/dashboard`} className={styles.menuLink}>
                            <div className={styles.iconAndName}>
                                <SiChartdotjs/>
                                Location Dashboard
                            </div>
                            <span className={styles.arrow}>›</span>
                        </Link>
                    </li>
                </div>
            </ul>
        </nav>

    );
};

export default LocationSettingsMenu;
