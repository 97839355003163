// LocationSettingsMenu.js
import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import styles from './LocationAdminMenu.module.css';
import { IoTicketOutline } from "react-icons/io5";
import { IoBagAddOutline } from "react-icons/io5";
import { IoBatteryChargingOutline } from "react-icons/io5";
import { IoBatteryDeadOutline } from "react-icons/io5";
import { IoStorefrontSharp } from "react-icons/io5";
import { SiHomeassistantcommunitystore } from "react-icons/si";
import { CgListTree } from "react-icons/cg";
import { TbSettings } from "react-icons/tb";
import { MdManageAccounts } from "react-icons/md";
import { SiChartdotjs } from "react-icons/si";
import { SiContactlesspayment } from "react-icons/si";
import { PiContactlessPayment } from "react-icons/pi";
import {CiLogout} from "react-icons/ci";
import SuperAdminSettingsMenu from "../SuperAdminSettings/SuperAdminSettingsMenu/SuperAdminSettingsMenu";





const LocationAdminMenu = ({ handleShowHeader, handleShowSettingsHeader, handleLogout, user }) => {

    useEffect(() => {
        handleShowHeader(false);
        handleShowSettingsHeader(true);
    }, [handleShowHeader, handleShowSettingsHeader]);

    return (
        <>
            <nav className={user.role === 'superadmin' ? styles.locationSettingsMenuSuperAdmin:styles.locationSettingsMenu}>
                <h2 className={styles.title}>Settings</h2>
                <ul className={styles.menuList}>
                    <div className={styles.section}>
                        <h2 className={styles.sectionHeader}>Manage your deals</h2>
                        <li className={styles.menuItem}>
                            <Link to="/location-settings/add-deal" className={styles.menuLink}>
                                <div className={styles.iconAndName}>
                                    <IoTicketOutline/>
                                    Add Deal
                                </div>
                                <span className={styles.arrow}>›</span>
                            </Link>
                        </li>
                    </div>

                    <div className={styles.section}>
                        <h2 className={styles.sectionHeader}>Set your location settings</h2>

                        <li className={styles.menuItem}>
                            <Link to="/location-settings/location-manager" className={styles.menuLink}>
                                <div className={styles.iconAndName}>
                                    <IoStorefrontSharp/>
                                    Location Manager
                                </div>
                                <span className={styles.arrow}>›</span>
                            </Link>
                        </li>
                    </div>
                    <div className={styles.section}>
                        <h2 className={styles.sectionHeader}>Update your payments</h2>

                        <li className={styles.menuItem}>
                            <Link to="/location-settings/subscription" className={styles.menuLink}>
                                <div className={styles.iconAndName}>
                                    <PiContactlessPayment/>
                                    Subscription Settings
                                    <span className={styles.arrow}>›</span>
                                </div>
                            </Link>
                        </li>
                    </div>
                    <div className={styles.section}>
                        <h2 className={styles.sectionHeader}>Logout</h2>

                        <li className={styles.menuItem}>
                            <Link className={styles.menuLink}>
                                <div className={styles.iconAndName} onClick={handleLogout}>
                                    <CiLogout/>
                                    Logout
                                </div>
                            </Link>
                        </li>
                    </div>
                </ul>
            </nav>
            {user.role === 'superadmin' && (<SuperAdminSettingsMenu user={user} handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} />)}
        </>
    )
};

export default LocationAdminMenu;

