import React, { useState, useEffect, useRef } from 'react';
import { Mic, StopCircle } from 'lucide-react';
import { useVoiceSearch } from './VoiceSearchContext';
import { useNavigate } from 'react-router-dom';

const VoiceGPTButton = ({ showPopup: initialShowPopup }) => {
    const {
        isListening,
        transcript,
        error,
        startListening,
        stopListening,
        setTranscript,
        setError,
        setShouldSearch
    } = useVoiceSearch();

    const [showPopup, setShowPopup] = useState(initialShowPopup);
    const recognitionRef = useRef(null);
    const timerRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        if ('webkitSpeechRecognition' in window) {
            recognitionRef.current = new window.webkitSpeechRecognition();
            recognitionRef.current.continuous = true;
            recognitionRef.current.interimResults = true;

            recognitionRef.current.onresult = (event) => {
                const currentTranscript = Array.from(event.results)
                                               .map(result => result[0].transcript)
                                               .join('');

                console.log(currentTranscript);
                setTranscript(currentTranscript);

                if (timerRef.current) clearTimeout(timerRef.current);
                timerRef.current = setTimeout(() => {
                    stopListening();
                    setShouldSearch(true);
                    navigate(`/search-all?q=${encodeURIComponent(currentTranscript)}`);
                }, 500);
            };

            recognitionRef.current.onerror = (event) => {
                setError('Error occurred in recognition: ' + event.error);
            };

            recognitionRef.current.onend = () => {
                stopListening();
            };
        } else {
            setError('Speech recognition not supported');
        }

        return () => {
            if (recognitionRef.current) recognitionRef.current.abort();
            if (timerRef.current) clearTimeout(timerRef.current);
        };
    }, [setTranscript, setError, stopListening, setShouldSearch, navigate]);

    useEffect(() => {
        if (isListening) {
            setShowPopup(true);
            if (recognitionRef.current) recognitionRef.current.start();
        } else {
            if (recognitionRef.current) recognitionRef.current.stop();
            const timer = setTimeout(() => setShowPopup(false), 2000);
            return () => clearTimeout(timer);
        }
    }, [isListening]);

    const toggleListening = () => {
        if (isListening) {
            stopListening();
            setShouldSearch(true);
        } else {
            startListening();
            setShouldSearch(false);
        }
    };

    return (
        <div style={{ position: 'relative' }}>
            <div
                onClick={toggleListening}
                style={{
                    width: '72px',
                    height: '72px',
                    borderRadius: '50%',
                    backgroundColor: '#775496',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
                    transform: 'translateY(-25px)',
                }}
            >
                {isListening ? <StopCircle color="white" size={30} /> : <Mic color="white" size={27} />}
            </div>
            {showPopup && (
                <div style={{
                    position: 'absolute',
                    bottom: '139%',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: '250px',
                    backgroundColor: 'rgba(0,0,0,0.8)',
                    borderRadius: '15px',
                    padding: '15px',
                    marginBottom: '10px',
                    color: 'white',
                    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                    zIndex: 100,
                }}>
                    <div style={{ marginBottom: '10px', minHeight: '20px' }}>
                        {isListening ? (transcript || "Listening...") : "Searching..."}
                    </div>

                </div>
            )}
        </div>
    );
};

export default VoiceGPTButton;
