import { useState, useCallback, useRef } from 'react';
import constants from './constants';
import authApi from './auth.api';

const useApi = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const abortControllerRef = useRef(null);

    const baseUrl = constants.API_URL;

    const handleError = useCallback((error) => {
        if (error.name === 'AbortError') {
            console.log('Request was cancelled');
        } else {
            console.error(error);
            setError(error.message);
        }
    }, []);

    const fetchData = useCallback(async (url, options) => {
        setIsLoading(true);
        setError(null);

        // Cancel any ongoing requests
        if (abortControllerRef.current) {
            //abortControllerRef.current.abort();
        }

        // Create a new AbortController for this request
        abortControllerRef.current = new AbortController();
        options.signal = abortControllerRef.current.signal;

        try {
            const response = await fetch(url, options);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            return data;
        } catch (error) {
            handleError(error);
            throw error;
        } finally {
            setIsLoading(false);
        }
    }, [handleError]);

    const getHeaders = useCallback(() => {
        return {
            ...authApi.authHeader(),
            'Accept': 'application/json',
        };
    }, []);

    const get = useCallback((endpoint) => {
        const url = `${baseUrl}${endpoint}`;
        return fetchData(url, {
            method: 'GET',
            headers: getHeaders()
        });
    }, [baseUrl, fetchData, getHeaders]);

    const post = useCallback((endpoint, data) => {
        const url = `${baseUrl}${endpoint}`;
        let body;
        let postHeaders = getHeaders();

        if (data instanceof FormData) {
            body = data;
        } else {
            body = JSON.stringify(data);
            postHeaders['Content-Type'] = 'application/json';
        }

        return fetchData(url, {
            method: 'POST',
            headers: postHeaders,
            body
        });
    }, [baseUrl, fetchData, getHeaders]);

    const put = useCallback((endpoint, data) => {
        const url = `${baseUrl}${endpoint}`;
        let body;
        let putHeaders = getHeaders();

        if (data instanceof FormData) {
            body = data;
            body.append('_method', 'PUT');
            // Remove Content-Type header if it exists
            delete putHeaders['Content-Type'];
        } else {
            body = JSON.stringify(data);
            putHeaders['Content-Type'] = 'application/json';
        }

        return fetchData(url, {
            method: 'POST', // We use POST and rely on _method for Laravel
            headers: putHeaders,
            body
        });
    }, [baseUrl, fetchData, getHeaders]);

    const deleteMethod = useCallback((endpoint) => {
        const url = `${baseUrl}${endpoint}`;
        return fetchData(url, {
            method: 'DELETE',
            headers: getHeaders()
        });
    }, [baseUrl, fetchData, getHeaders]);

    const submitDeal = useCallback((data) => {
        const formData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            if (key === 'image' && value instanceof File) {
                formData.append('image', value, value.name);
            } else if (key === 'starts' || key === 'ends') {
                formData.append(key, value.toISOString());
            } else if (key === 'product_ids' && Array.isArray(value)) {
                // Append each product_id separately
                value.forEach((productId, index) => {
                    formData.append(`product_ids[${index}]`, productId);
                });
            } else {
                formData.append(key, value);
            }
        });

        return post('deals', formData);
    }, [post]);

    const updateDeal = useCallback((id, data) => {
        const formData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            if (key === 'image' && value instanceof File) {
                formData.append('image', value, value.name);
            } else if (key === 'startsAt' || key === 'endsAt') {
                formData.append(key, value.toISOString());
            } else if (key === 'product_ids' && Array.isArray(value)) {
                // Append each product_id separately
                value.forEach((productId, index) => {
                    formData.append(`product_ids[${index}]`, productId);
                });
            } else {
                formData.append(key, value);
            }
        });

        return put(`deals/${id}`, formData);
    }, [put]);

    const deleteDeal = useCallback((id) => deleteMethod(`deals/${id}`), [deleteMethod]);
    const deleteDealProduct = useCallback((id, productId) => deleteMethod(`deals/${id}/product/${productId}`), [deleteMethod]);
    const getBusiness = useCallback((id) => get(`business/${id}`), [get]);
    const getBusinessMenu = useCallback((id) => get(`business/${id}/menu`), [get]);
    const getBusinessDeals = useCallback((id) => get(`business/${id}/deals`), [get]);

    const likeProduct = useCallback((productId, isLiked) => {
        const url = `products/${productId}/like`;
        return post(url, { liked: isLiked });
    }, [post]);

    const search = useCallback((term, city, sortBy) => {
        const params = new URLSearchParams({ city, term, sort_by: sortBy });
        return get(`search?${params}`);
    }, [get]);

    const searchApi = useCallback((geo, term, category) => {
        console.log(geo);
        let params = new URLSearchParams({  lat: geo.latitude, lon: geo.longitude, term });

        if(category){
            params = new URLSearchParams({ lat: geo.latitude, lon: geo.longitude, term, category });
        }
        return get(`search-api?${params}`);
    }, [get]);

    const searchGeo = useCallback((term, geo, sortBy) => {
        const params = new URLSearchParams({
                                               lat: geo.latitude,
                                               lon: geo.longitude,
                                               term,
                                               sort_by: sortBy
                                           });
        return get(`search-geo?${params}`);
    }, [get]);

    const searchProducts = useCallback((dispensaryId, term, productIds = []) => {
        const params = new URLSearchParams({ dispensaryId, term });

        // Add product_ids to the query parameters
        if (productIds.length > 0) {
            productIds.forEach((id, index) => {
                params.append(`product_ids[${index}]`, id);
            });
        }

        return get(`search-products?${params}`);
    }, [get]);

    const getDeal = useCallback((id) => get(`deals/${id}`), [get]);

    const getGeoposition = useCallback(() => get('geoposition'), [get]);
    const getCategories = useCallback(() => get('categories'), [get]);
    const getLocationDeals = useCallback((businessId) => get(`location-deals/${businessId}`), [get]);

    // Manage Users and Dispensaries
    const searchUsers = useCallback((query) => {
        return get(`users/search?term=${encodeURIComponent(query)}`);
    }, [get]);

    const searchDispensaries = useCallback((query, mine, id) => {
        if(id){
            return get(`search-locations?id=${encodeURIComponent(id)}`);
        }
        else if (query) {
            return get(`search-locations?term=${encodeURIComponent(query)}`);
        } else if (mine) {
            return get(`search-locations?mine=${encodeURIComponent(mine)}`);
        }
        return get(`search-locations`);
    }, [get]);
    const getUsers = useCallback(() => get('users'), [get]);
    const getDispensaries = useCallback(() => get('dispensaries'), [get]);
    const getDispensaryUsers = useCallback((dispensaryId) => {
        return get(`dispensaries/${dispensaryId}/users`);
    }, [get]);
    const getRoles = useCallback(() => get('roles'), [get]);

    const assignUserToDispensary = useCallback((userId, dispensaryId, roleId) => {
        return post('dispensaries/users', { user_id: userId, dispensary_id: dispensaryId, role_id: roleId });
    }, [post]);

    return {
        isLoading,
        error,
        assignUserToDispensary,
        deleteDeal,
        deleteDealProduct,
        getBusiness,
        getBusinessMenu,
        getBusinessDeals,
        getCategories,
        getDeal,
        getDispensaries,
        getDispensaryUsers,
        getGeoposition,
        getLocationDeals,
        getUsers,
        getRoles,
        likeProduct,
        search,
        searchApi,
        searchDispensaries,
        searchGeo,
    searchProducts,
        searchUsers,
        submitDeal,
        updateDeal
    };
};

export default useApi;
