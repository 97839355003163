import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useApi from '../../../utils/useApi'; // Adjust the import path as needed
import styles from './LocationSettings.module.css'; // Create this CSS module

const LocationSettings = () => {
    const { id } = useParams();
    const { getBusiness, updateBusiness } = useApi();
    const [locationData, setLocationData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchLocationData = async () => {
            try {
                const data = await getBusiness(id);
                setLocationData(data);
                setIsLoading(false);
            } catch (err) {
                setError(err.message);
                setIsLoading(false);
            }
        };

        fetchLocationData();
    }, [id, getBusiness]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLocationData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleScheduleChange = (day, field, value) => {
        setLocationData(prevData => ({
            ...prevData,
            schedule: {
                ...prevData.schedule,
                [day]: {
                    ...prevData.schedule[day],
                    [field]: value
                }
            }
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await updateBusiness(id, locationData);
            alert('Location settings updated successfully!');
        } catch (err) {
            setError(err.message);
        }
    };

    if (error) return <div>Error: {error}</div>;

    return (
        <div className={styles.container}>


            {isLoading && (<div>Loading...</div>)}
            {!locationData && !isLoading && (<div>No location data found.</div>)}

            {!isLoading && (
                <>
                    <h2>Location Settings for {locationData.name}</h2>

                    <form onSubmit={handleSubmit} className={styles.form}>
                        <div className={styles.formGroup}>
                            <label htmlFor="name">Name:</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={locationData.name}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className={styles.formGroup}>
                            <label htmlFor="address">Address:</label>
                            <input
                                type="text"
                                id="address"
                                name="address"
                                value={locationData.address}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className={styles.formGroup}>
                            <label htmlFor="city">City:</label>
                            <input
                                type="text"
                                id="city"
                                name="city"
                                value={locationData.city}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className={styles.formGroup}>
                            <label htmlFor="state">State:</label>
                            <input
                                type="text"
                                id="state"
                                name="state"
                                value={locationData.state}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className={styles.formGroup}>
                            <label htmlFor="zip_code">Zip Code:</label>
                            <input
                                type="text"
                                id="zip_code"
                                name="zip_code"
                                value={locationData.zip_code}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className={styles.formGroup}>
                            <label htmlFor="phone_number">Phone Number:</label>
                            <input
                                type="tel"
                                id="phone_number"
                                name="phone_number"
                                value={locationData.phone_number}
                                onChange={handleInputChange}
                            />
                        </div>

                        <h3>Business Hours</h3>
                        {Object.entries(locationData.schedule).map(([day, hours]) => (
                            <div key={day} className={styles.scheduleGroup}>
                                <h4>{day.charAt(0).toUpperCase() + day.slice(1)}</h4>
                                <div className={styles.hourInputs}>
                                    <input
                                        type="time"
                                        value={hours.open.slice(11, 16)}
                                        onChange={(e) => handleScheduleChange(day, 'open', `1901-01-01T${e.target.value}:00.000Z`)}
                                    />
                                    <span>to</span>
                                    <input
                                        type="time"
                                        value={hours.close.slice(11, 16)}
                                        onChange={(e) => handleScheduleChange(day, 'close', `1901-01-02T${e.target.value}:00.000Z`)}
                                    />
                                </div>
                            </div>
                        ))}

                        <button type="submit" className={styles.submitButton}>Save Changes</button>
                    </form>
                </>
            )}

        </div>
    );
};

export default LocationSettings;
