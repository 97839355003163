import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import useApi from '../../../utils/useApi';
import styles from '../Settings.module.css';
import {useParams} from "react-router-dom";
import DealList from "../../DealList/DealList";
import DealListAdmin from "../../DealList/DealListAdmin";

const DealManager = () => {
    const { id } = useParams();
    const [deals, setDeals] = useState([]);
    const { getLocationDeals, isLoading, error } = useApi();

    useEffect(() => {
        const fetchDeals = async () => {
            try {
                // Assuming we have a business ID, replace '123' with the actual ID
                const fetchedDeals = await getLocationDeals(id);
                setDeals(fetchedDeals);
            } catch (err) {
                console.error("Failed to fetch deals:", err);
            }
        };

        fetchDeals();
    }, [getLocationDeals]);

    const handleEdit = (dealId) => {
        // Implement edit functionality
        console.log(`Editing deal with id: ${dealId}`);
    };

    const formatDate = (dateString) => {
        return format(new Date(dateString), 'MMM dd, yyyy');
    };

    if (error) return <div>Error: {error}</div>;

    return (
        <div className={styles.container}>
            <h2>Deal Manager</h2>

            {isLoading && (<div>Loading deals...</div>)}
            <DealListAdmin deals={deals} />

        </div>
    );
};

export default DealManager;
